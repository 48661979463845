.replica-mode {
	--toc-width: 240px;
}

.replica-mode {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: calc(var(--navbar-height) + 5px) calc(
			100% - var(--navbar-height) - 5px
		);
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.replica-mode.no-toolbar {
	grid-template-rows: 100%;
}

.navbar-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 5px;
	gap: 0px 0px;
	height: 55px;
	grid-template-areas:
		'.'
		'.';
}

.progress {
	height: var(--progress-bar-height);
	position: relative;
	width: 100%;
	border-bottom: 1px solid #eaeaea;
}

.progress .bar {
	height: 100%;
	min-height: var(--progress-bar-height);
	transition: width ease-out 220ms;
}

.navbar {
	width: 100vw;
	display: grid;
	height: 50px;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	z-index: 9999;
	grid-template-areas: '. .';
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18),
		0 1px 1px rgba(0, 0, 0, 0.03) !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.navbar .logo {
	height: 100%;
	max-width: 100%;
	object-fit: cover;
}

.navbar > div {
	display: flex;
	max-height: calc(var(--navbar-height) - var(--progress-bar-height));
}

.navbar > div:last-of-type {
	justify-content: left;
}

.navbar > div:last-of-type {
	justify-content: right;
}

.navbar .actions {
	height: 100%;
	display: flex;
	align-items: center;
}

.navbar .actions button {
	width: 24px;
	height: 24px;
	margin: 0px 15px;
	padding: 0;
	border: none;
	background: none;
}

.content {
	height: 100%;
	display: flex;
	transition: width ease-out 220ms;
	position: relative;
	background: #2e3441;
}

.content .navigation-arrow {
	position: absolute;
	width: 50px;
	height: 50px;
	cursor: pointer;
	background: #fff;
	z-index: 200;
	top: 0;
	bottom: 0;
	margin: auto 0;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	opacity: 0.5;
	justify-content: center;
	align-items: center;
	border: 1px solid #eaeaea;
	transition: opacity ease-out 200ms;
}

.content .navigation-arrow:hover {
	opacity: 1;
}

.content .navigation-arrow.disabled {
	opacity: 0;
	display: none;
}

.content .navigation-arrow.left {
	left: 0;
	margin-left: 20px;
}

.content .navigation-arrow.right {
	right: 0;
	margin-right: 20px;
}

.toc {
	height: 100%;
	width: var(--toc-width);
	background: white;
	transition: width 220ms;
	border-right: 1px solid rgba(195, 195, 195, 0.5);
}

.toc ol {
	height: 100%;
	overflow: auto;
}

.toc.closed {
	width: 0px;
	transition: width ease-out 220ms;
}

.toc ~ .content {
	width: calc(100% - var(--toc-width));
}

.toc.closed ~ .content {
	width: 100%;
}

.toc-item {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px;
	cursor: pointer;
}

.toc-item span {
	padding: 6px;
	transition: background ease-out 220ms;
}

.toc-item figure {
	transition: background ease-out 220ms;
}

.toc-item:first-of-type {
	padding-top: 40px;
}

.toc-item img {
	width: 100%;
}

.toc-item.single {
	width: 100%;
	display: flex;
	align-items: center;
}

.toc-item.single.selected figure {
	background: #c4c4c4;
}

.toc-item.selected span {
	width: 25%;
	border-radius: 4px;
	background: rgba(195, 195, 195, 0.2);
	text-align: center;
}

.toc-item.single figure {
	width: 60%;
	margin-bottom: 15px;
}

.toc-item.single figure img {
	border: 1px solid rgba(195, 195, 195, 0.2);
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
}

.toc-item.double figure {
	max-width: 80%;
	display: flex;
	justify-content: center;
	border: 1px solid rgba(195, 195, 195, 0.2);
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
	margin-bottom: 15px;
}

.toc-item.double figure img {
	border: 0px solid rgba(195, 195, 195, 0.5);
}

.toc-item.double img {
	width: 50%;
}

.toc-item.double .single {
	box-shadow: none;
}

.toc-item.double .single > img {
	width: 100%;
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
}

/*.pages img {
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
}*/

/*Single page*/
.single-page {
	width: 100vw;
	height: 100%;
}

.single-page > :first-child {
	width: 100%;
	height: 100%;
}

.single-page .page {
	cursor: grab;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

/*
figure {
  width: 600px;
  background-repeat: no-repeat;
}

figure:hover img {
  opacity: 0;
}

img {
  display: block;
  width: 100%;
  pointer-events: none;
}

*/

.single-page img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	pointer-events: none;
}

.page a {
	position: absolute;
	width: 40px;
	height: 40px;
	margin-bottom: 20px;
	border-radius: 50%;
	background: #fff;
	border: 0px;
	bottom: 0;
	opacity: 0.2;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity ease-out 220ms;
}

.page a > div {
	width: 20px;
	height: 20px;
}

.page:hover a {
	opacity: 1;
}

.page a.left {
	left: 0;
	margin-left: 20px;
}

.page a.right {
	right: 0;
	margin-right: 20px;
}

/*Double page*/
.double-page-container {
	width: 100vw;
	height: 100%;
	border: 1px solid rgba(195, 195, 195, 0.2);
	margin: 0px auto;
}

.double-page-handler {
	/*display: flex;
	justify-content: center;
	align-items: center;*/
	height: 100%;
	max-height: 100%;
}

.double-page-handler .left figure > :first-child,
.double-page-handler .right figure > :first-child {
	margin-top: auto;
	margin-bottom: auto;
}

.double-page-handler .left figure{
	justify-content: flex-end;
}

.double-page-handler .left .action-bar{
	margin-right: 20vw;
}

.double-page-handler .left figure img {
	margin-left: auto;
}

.double-page-handler .right figure{
	justify-content: flex-start;
}

.double-page-handler .right .action-bar{
	margin-left: 20vw;
}

.double-page-handler .right figure img {
	margin-right: auto;
}

.double-page-handler > :first-child {
	margin-top: auto;
	margin-bottom: auto;
	height: 100%;
}

.double-page-container > :first-child {
	height: 100%;
	width: 100%;
}

.double-page {
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	max-height: 100%;
	grid-template-areas: '. .';
}

.double-page > div {
	overflow: hidden;
	cursor: grab;
	width: 50vw;
	height: 100%;
	display: flex;
}

.double-page > :first-child {
	justify-content: flex-end;
}

.double-page figure {
	display: flex;
	position: relative;
}

.double-page img {
	object-fit: contain;
	max-height: 100%;
	max-width: 100%;
}

.double-page a {
	position: absolute;
	width: 40px;
	height: 40px;
	margin-bottom: 20px;
	border-radius: 50%;
	background: #fff;
	border: 1px solid #c3c3c3;
	bottom: 0;
	opacity: 0.2;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity ease-out 220ms;
}

.double-page a:hover {
	opacity: 1;
}

.double-page a > div {
	width: 20px;
	height: 20px;
}

.double-page figure:hover a {
	opacity: 1;
}

.double-page a.left {
	left: 0;
	margin-left: 20px;
}

.double-page a.right {
	right: 0;
	margin-right: 20px;
}

.double-page figure,
.single-page figure {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	/*align-items: center;*/
	background-repeat: no-repeat;
	position: relative;
}

.double-page figure img,
.single-page figure img {
	transition: opacity ease-out 220ms;
}

figure.image-container {
	cursor: zoom-in;
	transition: background-image ease-out 220ms;
}

.double-page figure.image-container:hover img,
.single-page figure.image-container:hover img {
	opacity: 0;
}

.single-page figure.clickable:hover,
.double-page img.clickable:hover {
	cursor: pointer;
}

.action-bar {
	background: rgba(0, 0, 0, 0.75);
	min-width: 60px;
	height: 50px;
	position: absolute;
	bottom: 0;
	margin-bottom: 5%;
	color: #fff;
	border-radius: 9px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity ease-out 220ms;
}

.action-bar button {
	width: 40px;
	height: 40px;
	background: transparent;
	border: none;
	border-radius: 5px;
	transition: background ease-out 220ms;
	margin: 0px 5px;
}

.action-bar button:hover,
.action-bar button.active {
	background: rgb(97, 97, 97);
}

.single-page figure:hover .action-bar,
.double-page figure:hover .action-bar {
	opacity: 1;
}

.double-page figure:not(:hover) {
	background-image: none;
}

@media (hover: none) and (pointer: coarse) {
	.action-bar {
		opacity: 1;
		min-width: 35px;
		height: 35px;
		border-radius: 5px;
		bottom: 0;
		margin-bottom: 2%;
	}

	.action-bar.left {
		left: 0;
		margin-left: 2%;
	}

	.action-bar.right {
		right: 0;
		margin-right: 2%;
	}

	.action-bar button {
		width: 35px;
		height: 35px;
		border: none;
		border-radius: 5px;
	}

	.action-bar button div {
		-webkit-mask-size: 24px 24px;
		mask-size: 24px 24px;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
	}
}

@media only screen and (max-width: 480px) {
	.navbar {
		grid-template-columns: unset;
	}

	.toc {
		height: 100%;
		width: 100vw;
	}

	.toc.closed {
		width: 0px;
	}

	.toc ~ .content {
		width: 0px;
	}

	.toc.closed ~ .content {
		width: 100vw;
	}

	.page figure > :first-child {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
}

@media only screen and (min-width: 1400px) {
	.double-page-handler .left figure > :first-child,
	.double-page-handler .right figure > :first-child {
		margin-top: unset;
		margin-bottom: unset;
	}
}
