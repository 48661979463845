.issue {
	width: 100%;
	height: 100%;
}

.issue .container {
	position: relative;
	min-height: calc(100% - 5px - var(--navbar-height));
	height: calc(100% - 5px - var(--navbar-height));
	width: 100%;
}

.issue .container aside {
	transition: tranform ease-out 200ms;
}

.issue .container main {
	min-width: calc(100% - 300px);
	width: calc(100% - 300px);
	max-width: calc(100% - 300px);
	margin-left: 300px;
	transition: margin ease-out 200ms, width ease-out 200ms;
}

.issue.toc-close .container aside {
	transform: translateX(-300px);
	transition: tranform ease-in 250ms;
}

.issue.toc-close .container main {
	min-width: 100%;
	width: 100%;
	margin-left: 0px;
}

.navbar {
	grid-area: navbar;
	display: grid;
	background: black;
	z-index: 20;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: 'logo actions';
	padding: 0px 0px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.navbar button {
	width: 24px;
	height: 24px;
	margin: 0px 15px;
	padding: 0;
	border: none;
	background: none;
}

.navbar .logo {
	grid-area: logo;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar .actions {
	grid-area: actions;
}

.navbar > :first-child {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.navbar > :first-child img {
	cursor: pointer;
}

.navbar > :last-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 15px;
}

.navbar .loading {
	width: 24px;
	height: 24px;
	cursor: wait;
}

.navbar .loading svg {
	display: flex;
	justify-content: center;
	align-items: center;
}

.offline {
	display: flex;
	justify-content: center;
	width: 24px;
	height: 100%;
}

.offline > * {
	width: 24px;
	height: 24px;
	margin: auto;
}

.search-bar {
	display: flex;
	position: relative;
	height: 32px;
	margin-left: 7px;
	width: 30px;
	transition: width ease-out 220ms;
}

.share-button > div {
	mask-size: 95%;
}

.search-bar.expanded {
	width: 200px;
}

.search-results {
	position: absolute;
	width: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0;
	min-height: 32px;
	max-height: 300px;
	z-index: 100;
	background: white;
	right: 0;
	margin-right: 15px;
	border-radius: 2px;
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
}

.search-results.empty {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.search-results.empty > small {
	font-style: italic;
	font-size: 0.8rem;
	opacity: 0.5;
}

.search-results ul {
	width: 100%;
}

.search-results ul > :not(:last-child) {
	border-bottom: 1px solid #eaeaea;
}

.search-results ul li {
	overflow: hidden;
	width: 100%;
	cursor: pointer;
}

.search-results .item {
	height: 60px;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.search-results .image {
	overflow: hidden;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-results .image img {
	object-fit: cover;
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 4px;
}

.search-results .description {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-right: 12px;
}

.search-results .description h4 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 0.8rem;
	max-width: calc(100%);
}

.search-results .description small {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.7rem;
	font-style: italic;
	color: #c4c4c4;
	white-space: nowrap;
	margin-top: 4px;
}

.search-bar .icon {
	position: absolute;
	height: 100%;
	width: 18px;
	margin-left: 8px;
	display: flex;
	justify-content: center;
}

.search-bar .icon.close {
	margin-right: 8px;
	width: 18px;
	right: 0;
}

.search-bar .icon.close > div {
	-webkit-mask-size: 18px 18px;
	mask-size: 18px 18px;
}

.search-bar input {
	width: 100%;
	height: 100%;
	padding-left: 32px;
	padding-right: 32px;
	border-radius: 4px;
	outline: none;
	border: 1px solid #c4c4c4;
	font-size: 0.9rem;
	color: #3d3d3d;
}

.progress-bar {
	height: 5px;
	border-bottom: 1px solid #eaeaea;
}

.progress-bar > div {
	height: 100%;
	transition: all ease-out 200ms;
}

.toc {
	min-width: 300px;
	width: 300px;
	height: 100%;
	position: absolute;
	overflow: auto;
	border-right: 1px solid #eee;
	list-style-position: inside;
}

.toc > li {
	display: block;
	display: list-item;
	padding: 1.4em;
	font-size: 0.9rem;
	line-height: 1.5;
}

.toc .item {
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
	cursor: pointer;
	transition: color ease-out 200ms, border ease-out 200ms;
	padding: 1rem;
}

.toc .item .item-content {
	height: 100%;
	padding-left: 8px;
	max-width: 100%;
	padding-right: 8px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.toc .item p {
	font-size: 0.8rem;
	color: #c4c4c4;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.toc .item span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.toc .item small {
	font-size: 0.7rem;
	color: #c4c4c4;
	font-style: italic;
}

.toc .image {
	overflow: hidden;
	width: 60px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toc .image img {
	object-fit: cover;
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 4px;
}

.content {
	position: absolute;
	transition: width ease-out 200ms;
}

.content .toc-toggle {
	position: absolute;
	bottom: 0;
	margin-bottom: 50px;
	margin-left: 20px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 100;
	border: 0px;
	background: #fff;
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
}

.font-slider {
	float: left;
	position: fixed;
	min-width: 325px;
	width: 25%;
	max-width: 400px;
	height: var(--navbar-height);
	background: white;
	z-index: 200;
	right: 0;
	margin-top: calc(var(--navbar-height) + var(--progress-bar-height));
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
	display: grid;
	grid-template-columns: 32px 1fr 32px;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. . .';
	padding: 0px 10px;
	transition: all ease-out 200ms;
}

.font-slider.hidden {
	height: 0px !important;
	grid-template-rows: 0fr;
}

.font-slider.hidden button,
.font-slider.hidden div {
	height: 0px;
	display: none;
	transition: all ease-in 50ms;
}

.font-slider div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.font-slider button {
	border: none;
	background: none;
	min-width: 40px;
	width: 40px;
	min-height: 30px;
	height: 30px;
}

.font-slider button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

/* Special styling for WebKit/Blink */
.font-slider input[type='range'] {
	border-radius: 8px;
	height: 7px;
	width: 80%;
	outline: none;
	transition: background 450ms ease-in;
	-webkit-appearance: none;
}

.font-slider input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3d3d3d;
}

.font-slider input[type='range']::-moz-range-thumb {
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3d3d3d;
	border: 1px solid #000000;
}

.font-slider input[type='range']::-ms-thumb {
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3d3d3d;
	border: 1px solid #000000;
}

/*Navigation Arrow*/
.navigation-arrow {
	position: absolute;
	width: 70px;
	top: 50%;
	height: auto;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navigation-arrow button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 100;

	background: none;
	opacity: 0.5;
	border: 1px solid #eaeaea;
	background: #fff;
	transition: opacity ease-out 200ms;
}

.navigation-arrow.left {
	left: 0;
}

.navigation-arrow.left button {
	margin-left: 20px;
}

.navigation-arrow.right {
	right: 0;
}

.navigation-arrow.right button {
	margin-right: 20px;
}

.navigation-arrow.left:hover button,
.navigation-arrow.right:hover button {
	opacity: 1;
}

.share-container {
	float: left;
	position: fixed;
	min-width: 50px;
	width: 150px;
	height: 84px;
	z-index: 100;
	top: 0;
	background: #fff;
	right: 0;
	display: flex;
	margin-right: 0px;
	flex-direction: column;
	border: 1px solid #eaeaea;
	overflow: hidden;
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
	margin-top: calc(var(--navbar-height) + var(--progress-bar-height));
}

.share-container.hidden {
	height: 0px;
	border: 0px;
	transition: height ease-out 200ms;
}

.share-container button {
	border: none;
	height: 42px;
	display: flex;
	background: transparent;
	align-items: center;
	transition: height ease-out 220ms;
}

.share-container button > :first-child {
	height: 32px;
	width: 32px;
}

.share-container button h4 {
	font-size: 1rem;
	margin-left: 8px;
}

.share-container > :first-child {
	border-bottom: 1px solid #eaeaea;
}

.share-container.hidden button {
	height: 0px;
	border: 0;
	opacity: 0;
}

/*Print view*/
.print-view section {
	height: auto;
	min-height: auto;
	width: 100%;
}

.print-view main {
	min-width: 100% !important;
	width: 100% !important;
	margin-left: 0 !important;
}

.print-view > div {
	width: 100%;
	margin: auto;
	max-width: 720px;
	height: 100vh !important;
}

.print-view article .canvas {
	display: block;
	position: relative;
	height: auto !important;
	overflow: visible !important;
}

.print-view .navigation-arrow {
	visibility: hidden;
}

.print-view .container {
	min-height: 100%;
	height: 100%;
}

.print-view main > div > div {
	overflow: unset !important;
}

/*Iframe view*/

.iframe-view {
	height: 100%;
}

.iframe-view nav,
.iframe-view aside,
.iframe-view .progress-bar {
	display: none;
}

.iframe-view main {
	min-width: 100% !important;
	width: 100% !important;
	max-width: 100% !important;
	margin-left: 0px !important;
}

/*PLAYER TOOLBAR*/
.player-toolbar {
	width: 25vw;
	max-width: 300px;
	background: white;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr 100px;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
	min-height: var(--bottom-bar-height);
	top: 0;
	right: 0;
	z-index: 10;
	border: 1px solid #eaeaea;
	transition: margin-top ease-out 220ms;
}

.player-toolbar.show {
	margin-top: 0;
}

.player-toolbar.hidden {
	margin-top: calc(-1 * var(--bottom-bar-height));
}

.player-toolbar .controls {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.player-toolbar .controls button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.player-toolbar .controls button:disabled {
	opacity: 0.5;
}

.player-toolbar .progress {
	display: flex;
	justify-content: center;
	align-items: center;
}
