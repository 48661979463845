.issue {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.issue main {
	min-height: calc(
		100% - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	height: calc(
		100% - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.issue footer {
	position: relative;
	background: #3d3d3d;
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	z-index: 100;
	min-height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	max-height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	padding-bottom: env(safe-area-inset-bottom, var(--safe-area-inset));
	border-top: 1px solid #e0e4ea;
}

.progress-bar > div {
	height: 100%;
	transition: width ease-out 220ms;
}

.toolbar button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.toolbar button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.navbar {
	min-height: var(--navbar-height);
	max-height: var(--navbar-height);
	display: flex;
	justify-content: space-between;
	background: black;
	padding: 0px 0px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
	z-index: 150;
}

.navbar div {
	display: flex;
	align-items: center;
}

/*Search view*/

.search-view {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
}

.search-view .navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #3d3d3d;
}

.search-bar {
	width: 100%;
	height: 36px;
	position: relative;
}

.search-bar .icon {
	width: 24px;
	margin-left: 10px;
	opacity: 0.5;
	height: 24px;
	position: absolute;
}

.search-bar input {
	width: 98%;
	margin: auto;
	height: 100%;
	border: 0px;
	overflow: hidden;
	color: #fff;
	border-radius: 6px;
	background: #636363;
	padding-left: 32px;
	font-size: 1rem;
}

.search-bar input:focus {
	outline: none;
}

.search-bar input::placeholder {
	color: #fff;
	font-size: 0.9rem;
	opacity: 0.5;
}

.search-view ul > :not(:last-child) {
	border-bottom: 1px solid #eaeaea;
}

.search-view li {
	height: 80px;
	width: 100%;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.search-view li > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-view li > :last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	overflow: hidden;
	padding-right: 12px;
}

.search-view li h4 {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.8rem;
	max-width: calc(100%);
	white-space: nowrap;
}

.search-view li small {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.7rem;
	font-style: italic;
	color: #c4c4c4;
	white-space: nowrap;
	margin-top: 4px;
}

.search-view li img {
	width: 60%;
	height: 60%;
	object-fit: contain;
	overflow: hidden;
	border-radius: 4px;
}

.navbar button {
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: none;
}

.search-view main {
	overflow: auto;
}

/*ISSUEE*/

.issue-view {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
}

.issue-view .navbar {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: 'logo actions';
	min-height: var(--navbar-height);
	grid-template-rows: 1fr;
	gap: 0px 0px;
	height: 100%;
}

.navbar .logo {
	grid-area: logo;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.navbar .logo a {
	max-width: 100%;
}

.navbar .logo img {
	/*max-width: 100%;*/
}

.navbar .actions {
	grid-area: actions;
}

.issue-view .navbar button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.issue-view .navbar > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.issue-view .navbar > :first-child img {
	height: var(--navbar-height);
	min-height: var(--navbar-height);
	object-fit: cover;
}

.issue-view .navbar > :last-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.issue-view main {
	position: relative;
	overflow: hidden;
}

/*.issue-view aside,
.issue-view section {
	overflow: hidden;
	overflow-y: auto;
}*/

.issue-view .content {
	width: 100%;
	height: 100%;
	transition: transform ease-out 220ms;
	/*overflow-y: auto;*/
}

.issue-view .content > :first-child {
	min-height: 5px;
	height: 5px;
	position: relative;
	width: 100%;
	z-index: 10;
	background: #fff;
	border-bottom: 1px solid #eaeaea;
}

/*.issue-view .content > :last-child {
	height: calc(100% - 5px);
}*/

.issue-view aside {
	position: absolute;
	width: 100%;
	height: 100%;
	transition: transform ease-out 220ms;
}

.toc-closed aside {
	transform: translateX(-100%);
}

.toc-open aside {
	transform: translateX(0%);
}

.issue-view aside ul > :not(:last-child) {
	border-bottom: 1px solid #eaeaea;
}

.issue-view aside li {
	height: 80px;
	width: 100%;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.issue-view aside li.selected {
	font-weight: bold;
	font-family: Gibson-Semibold;
	border-left: 5px solid #000;
}

.issue-view aside li > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
}

.issue-view aside li .item-content {
	height: 100%;
	padding-left: 8px;
	max-width: 100%;
	padding-right: 12px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.issue-view aside li img {
	width: 60%;
	height: 60%;
	border-radius: 4px;
	object-fit: contain;
}

.issue-view aside li h4 {
	font-size: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1.4;
}

.issue-view aside li p {
	font-size: 0.8rem;
	color: #c4c4c4;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.issue-view aside li small {
	font-size: 0.7rem;
	color: #c4c4c4;
	font-style: italic;
}

.issue-view aside li br {
	height: 5px;
}

.toc-open section {
	transform: translateX(100%);
	transition: transform ease-out 220ms;
}

/*PLAYER TOOLBAR*/
.player-toolbar {
	width: 100%;
	background: white;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: '. . .';
	min-height: var(--bottom-bar-height);
	bottom: 0;
	border: 1px solid #eaeaea;
}

.player-toolbar.show {
	z-index: 300;
	margin-bottom: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.player-toolbar.hidden {
	z-index: 0;
	margin-bottom: 0;
}

.player-toolbar .thumbnail {
	display: flex;
	justify-content: center;
	align-items: center;
}

.player-toolbar .thumbnail img {
	width: calc(var(--bottom-bar-height) * 0.75);
	height: calc(var(--bottom-bar-height) * 0.75);
	object-fit: cover;
	overflow: hidden;
	border-radius: 4px;
}

.player-toolbar .controls {
	display: flex;
	justify-content: center;
	align-items: center;
}

.player-toolbar .controls button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.player-toolbar .controls button:disabled {
	opacity: 0.5;
}

.player-toolbar .progress {
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #c4c4c4;
}

.empty > div {
	display: flex;
	flex-direction: column;
}

.not-found {
	width: 100%;
	height: 100%;
	color: #c4c4c4;
	display: flex;
	justify-content: center;
	align-items: center;
}

.not-found i {
	font-style: italic;
}

.empty h3,
.empty p,
.not-found h3,
.not-found p {
	text-align: center;
}

.empty > div > :first-child {
	width: 30vw;
	height: 30vw;
	margin: auto;
	mask-size: 100%;
}

/*Iframe*/
.iframe-view {
	width: 100%;
	height: 100%;
	overflow: auto;
	display: block;
}

.iframe-view .navbar,
.iframe-view aside,
.iframe-view footer,
.iframe-view > div {
	display: none;
	min-height: 0;
}

.iframe-view main {
	min-height: 100%;
	height: 100%;
}

.toc-open {
	overflow-y: auto !important;
}

/*Navigation Arrow*/
.navigation-arrow {
	position: absolute;
	width: 70px;
	top: 50%;
	height: auto;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navigation-arrow button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 100;

	background: none;
	opacity: 0.5;
	border: 1px solid #eaeaea;
	background: #fff;
	transition: opacity ease-out 200ms;
}

.navigation-arrow.left {
	left: 0;
}

.navigation-arrow.left button {
	margin-left: 20px;
}

.navigation-arrow.right {
	right: 0;
}

.navigation-arrow.right button {
	margin-right: 20px;
}

.navigation-arrow.left:hover button,
.navigation-arrow.right:hover button {
	opacity: 1;
}
